import React from 'react';
// import React, { useState } from 'react';

import Container from 'react-bootstrap/Container';
// import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import './app.css';
import Logo from './images/logo.svg';
import LogoSymbol from './images/symbol_yellow.svg';

const App = () => (
  <Container>
    <Stack className="header">
      <Image src={Logo} className="logo" />
    </Stack>
    <Stack className="headline">
      <div className="inter-headline title">This is Turbine</div>
      <div className="inter-body description">At Turbine Studios in Malmö, we blend tech expertise with entrepreneurship, driven by transparency and collaboration. As an employee-owned company, we’re focused on delivering long-term value for our clients while shaping a healthier consulting industry.</div>
    </Stack>
    <Stack className="content">
      <div className="inter-headline title">Think Studio, Work Consultancy</div>
      <div className="inter-body description">
        <p>
          We’re reimagining what a consultancy can be by building it like a studio - flexible, creative, and centered around co-ownership. It’s about shaping work that feels meaningful and impactful, together.
        </p>
        <p>
          If you’re a senior backend engineer, solution architect, or technical business analyst looking for a rare opportunity, this might be it. Limited seats, big ambitions - let’s chat.
        </p>
        <p>
          Let’s talk! Send your CV to <a href="mailto:mynextjourney@turbinestudios.se">mynextjourney@turbinestudios.se</a>
        </p>
        <p>
          Don’t forget to connect with us on <a href="https://www.linkedin.com/company/turbine-studios-ab/">LinkedIn</a>
        </p>
      </div>
    </Stack>
    <Stack className="footer">
      <Row >
        <Col sm={6} md={4} className="footer-col1 text-center"><Image className="logo-symbol" src={LogoSymbol} /></Col>
        <Col md={4} sm={6} className="footer-col2 text-center ms-auto">
          <div className="title1">Tech Powered.</div>
          <div className="title2">Co-owned.</div>
        </Col>
        <Col className="footer-col3 text-center">
          <div className="footer-container">
            <div className="inter-headline title">Get in touch!</div>
            <div className="inter-body text">+46 (702) 61 10 73</div>
            <div className="inter-body text"><a href="mailto:info@turbinestudios.se">info@turbinestudios.se</a></div>
          </div>
        </Col>
      </Row>
    </Stack>
  </Container>
);

export default App;
